import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import './lib/slick.min';

$(document).foundation();

var fit_top_panel = function() {
    var min_h = 600;
    var scroll = $(window).scrollTop();
    if(window.innerWidth>1024)
        min_h = 800;
    if(window.innerHeight>min_h && scroll==0)
        $('.panel-top').height(window.innerHeight + 'px');
}

$(document).ready(function() {
    fit_top_panel();
    $(window).on('resize', function(){
        fit_top_panel();
    });

    $('#menu-toggle').click(function () {
        $('.menu').toggleClass('active');
        $('#menu-toggle').toggleClass('active');
    });
    $('.menu-wrapper ul li a').click(function () {
        $('.menu').toggleClass('active');
        $('#menu-toggle').toggleClass('active');
    });

    var lastScrollTop = 0;
    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        var st = $(this).scrollTop();
        if (scroll <= 10) {
          $('body').addClass('top');
          $('body').removeClass('up');
          $('body').removeClass('down');
          $('#menu-toggle').removeClass('mt-bar');
        } else {
          if (st > lastScrollTop) {
            $('body').addClass('down');
            $('body').removeClass('up');
            $('#menu-toggle').addClass('mt-bar');
          } else {
            $('body').addClass('up');
            $('body').removeClass('down');
            $('#menu-toggle').addClass('mt-bar');
          }
          lastScrollTop = st;
        }
    });
    $('#big-slider').slick({
      autoplay: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      adaptiveHeight: true
    });
});